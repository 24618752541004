import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MOBILE_TOP_MENU_HEIGHT } from 'constants/sizes';
import { Layout } from 'UI';

export const Container = styled.div`
  min-height: 100vh;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: calc(${MOBILE_TOP_MENU_HEIGHT}px + 3em);
  }
`;

export const Wrapper = styled(Layout)`
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
    padding-bottom: 20px;
  }
`;

export const JobContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-column: 3/9;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 2/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Department = styled.p`
  color: var(--red);
`;

export const Title = styled.h3``;
