import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Tooltip } from 'UI';

import {
  Wrapper, Mail, Share, Greenhouse,
} from './styled';

const query = graphql`
  query {
    page: strapiPrivacyPolicyPage {
      greenhouse_agreement
    }
  }
`;

interface IButtons {
  id: number;
  greenhouse?: string;
}

interface IQueryPolicty {
  page: {
    greenhouse_agreement: string;
  };
}

const Buttons: FC<IButtons> = ({ id, greenhouse }) => (
  <Wrapper>
    <Mail href="mailto:hr@wayray.com" title="hr@wayray.com">
      hr@wayray.com
    </Mail>
    <Share url={`https://wayray.com/careers/vacancy/${id}`}>
      Share
    </Share>
    {greenhouse && (
      <StaticQuery
        query={query}
        render={(data: IQueryPolicty) => {
          const agreement = data.page.greenhouse_agreement;

          return (
            <Tooltip text={agreement} place="top">
              <Greenhouse link={greenhouse}>
                Apply
              </Greenhouse>
            </Tooltip>
          );
        }}
      />

    )}
  </Wrapper>
);

export default Buttons;
