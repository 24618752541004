import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1.5em;
  padding: 1.5em 0;
`;

export const Section = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1em;
`;

export const SectionTitle = styled.p`
  font-weight: 600;
`;

export const TextWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1em;

  li {
    margin: 8px 0;
  }

  p {
    margin: 1em 0;
  }
`;

export const Footer = styled.div`
  display: grid;
  gap: 1em;
`;

export const Footnote = styled.small`
  color: var(--gray);
`;
