import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import Head from 'components/Layout/Head/Head';
import { IStrapiVacancy } from 'interfaces/vacancy';

import Position from 'components/Jobs/Position/Position';

import {
  Container, Wrapper, Department, Title, JobContainer,
} from './vacancy.styled';

interface IVacancyPage extends PageProps {
  pageContext: {
    vacancy: IStrapiVacancy;
  }
}

const VacancyPage: FC<IVacancyPage> = ({
  pageContext: { vacancy },
}) => (
  <>
    <Head
      title={vacancy.title}
      slug={`careers/vacancy/${vacancy.id}`}
    />
    <Container>
      <Wrapper>
        {vacancy?.id ? (
          <JobContainer>
            <Department>
              {vacancy.department.name}
              {vacancy?.location?.city && ` | ${vacancy.location.city}`}
              {vacancy?.location?.country && `, ${vacancy.location.country}`}
              {(vacancy?.location && vacancy?.remote) && ' (Office or Remote)'}
              {!vacancy.location && '| Remote'}
            </Department>
            <Title>{vacancy.title}</Title>
            <Position position={vacancy} />
          </JobContainer>
        ) : (
          <div />
        )}
      </Wrapper>
    </Container>
  </>
);

export default VacancyPage;
