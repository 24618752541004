import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH,
} from 'constants/sizes';

import { MailButton, ShareButton, GreenhouseButton } from 'UI';

export const Mail = styled(MailButton)`
  grid-area: m;
`;

export const Share = styled(ShareButton)`
  grid-area: s;
`;

export const Greenhouse = styled(GreenhouseButton)`
  grid-area: g;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1em;

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'm s g';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'm g'
      's s';
  }
`;
