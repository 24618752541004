import React, { FC } from 'react';
import Markdown from 'markdown-to-jsx';

import { IStrapiVacancy } from 'interfaces/vacancy';

import Buttons from './Buttons/Buttons';
import {
  SectionTitle, Container, Section, TextWrapper, Footer, Footnote,
} from './styled';

interface IPositionElement {
  className?: string;
  position: IStrapiVacancy;
}

const Position: FC<IPositionElement> = ({ className = '', position = null }) => {
  if (!position) return null;
  const { id, section, greenhouse } = position;

  return (
    <Container className={className}>
      {section.map(({ title, text, id: sectionId }) => (
        <Section key={`position_${sectionId}`}>
          <SectionTitle>{title}</SectionTitle>
          <TextWrapper>
            <Markdown>
              {text}
            </Markdown>
          </TextWrapper>
        </Section>
      ))}

      <Footer>
        <Footnote>
          Send us your CV and cover letter.
          <br />
          No matching job posted? Email us anyway!
        </Footnote>
        <Buttons id={id} greenhouse={greenhouse} />
      </Footer>
    </Container>
  );
};

export default Position;
